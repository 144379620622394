
.Invalid {
    border: 1.4px solid #FF424D !important;
}

.Required {
    color: #FF424D
}

.CountryCode.CountryCode {
    width: 33%;
    margin-right: 2%;
    display: inline-block;
    text-align: center;
}

.Phone.Phone {
    width: 63%;
    margin-left: 2%;
}

.CheckboxLabel {
    font-family: 'Source Sans Pro';
    text-transform: none;
    font-weight: normal;
    font-size: 1.3em;
    display: inline-block;
}

.Checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    margin-top: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    width: 20px;
  }
   
  .Checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: #FFFFFF;
    border: 1px solid #BEBEBE;
  }
  
  .Checkbox input:checked ~ .Checkmark {
    background-color: #FFC120;
    border: 1px solid #FFC120;
  }
  
  .Checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .Checkbox input:checked ~ .Checkmark:after {
    display: block;
  }
  
  .Checkbox .Checkmark:after {
    left: 6px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .Error {
    color: red;
    font-weight: 400;
    font-family: 'Source Sans Pro';
    font-size: 0.75em;
    height: 18px;
    margin: 0;
    margin-left: 1px;
    margin-top: 5px;
  }

  @media only screen and (min-width: 768px) {
    .Checkbox {
      margin-top: 0px;
    }

    .CountryCode.CountryCode {
      width: 31%;
      margin-right: 2%;
      display: inline-block;
      text-align: center;
  }
  
  .Phone.Phone {
      width: 65%;
      margin-left: 2%;
  }

  .Checkmark {
    margin-top:3px;
  }

  .Error {
    height: 30px;
  }
}