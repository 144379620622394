.CardActivator {
    margin: 5%;
}

.VipCardLogo {
    height: 40px;
    position: relative;
    top: 10px;
}

.Inline {
    display: inline !important;
}
 
.Logo {
    height: 50px;
    margin-bottom: 45px;
    display: block;
}

.SuperclientLogo {
    margin-top: 5px;
    height: 50px;
    display: block;
}


.HeaderHead {
    margin-bottom: 0;
    font-weight: 500;
    display: block;
}

.HeaderText {
    font-size: 0.85em;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    margin-bottom: 37px;
}

.CardActivator form {
    font-family: 'Source Sans Pro Semibold'
}

.CardActivator form input {
    font-size: 0.90em;
    font-weight: normal;
    width: 100%;
    background: #FFFFFF;
    border: 1.4px solid #BEBEBE;
    box-sizing: border-box;
    padding: 8px 15px;
    margin: 4px 0 0 0;
}

.CardActivator form label {
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: bold;
    letter-spacing: 0.01em;
    display: block;
    width: 100%;
}

.Activate {
    width: 56%;
    margin: 25px 22%;
    height: 45px;
    background: #FFC120;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.94em;
    letter-spacing: 0.02em;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    text-align: center;
    cursor: pointer;
}

.Activate:hover {
    background: #FFD361;
}

.Activate:disabled {
    background: #BEBEBE;
}

.Activate:active {
    background: #E9A31D;
}

.Error {
    color: red;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .CardActivator {
        width: 474px;
        margin-top: 70px;
        margin-left: 15.5%;
        height: 668px;
    }

    .HeaderHead {
        font-size: 28px;
        left: 20px;
    }

    .HeaderText {
        font-size: 16px;
    }

    .Logo {
        position: absolute;
        right: 10%;
        top: 8%;
    }

    .CardActivator form input {
        font-size: 18px;
        margin: 4px 0 0 0;
    }
    
    .CardActivator form label {
        font-size: 14px;
    }

    .Activate {
        font-size: 18px;
        height: 58px;
    }

    .HeaderWithImg {
        margin-left: 23px;
        margin-right: 4px;
        width: 361px;
        display: inline-block;
    }
    
    div.SuperclientLogo{
        margin-top: 0;
        height: 100%;
        width: 86px;    
        position: relative;
        /* top: -50px; */
        display: inline-block;
    }

    img.SuperclientLogo {
        width: 86px;
        height: 86px;
    }
  }

  
@media only screen and (min-width: 768px) and (max-width: 950px) {
    .Logo {
        position: absolute;
        right: 5%;
        top: 2%;
    }
}