.LocationList {
    font-family: 'Source Sans Pro';
    height: calc(100vh - 340px);
}

.Location {
    margin-right: 18px;
    border-bottom: 1px solid #BEBEBE;
}

.LocationName {
    font-family: 'Source Sans Pro Semibold';
    font-size: 1em;
    margin-top: 5px;
    margin-bottom: 3px;
}

.LocationAddress {
    margin-top: 0px;
    margin-bottom: 6px;
    font-size: 0.93em;
    color: #585858;
}

@media only screen and (min-width: 768px) {
    .LocationList {
        max-height: 535px;
        min-height: 100px;
        height: calc(100vh - 340px);
    }

    .LocationName {
        font-size: 18px;
    }

    .LocationAddress {
        font-size: 18px;
    }
  }