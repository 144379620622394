body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (min-width: 768px) {
  body {
    background-image: url(/static/media/car-background.0a4d24e4.jpg);
  }
}
.CardActivator_CardActivator__1P0bK {
    margin: 5%;
}

.CardActivator_VipCardLogo__1pTjl {
    height: 40px;
    position: relative;
    top: 10px;
}

.CardActivator_Inline__1AHac {
    display: inline !important;
}
 
.CardActivator_Logo__3B9K0 {
    height: 50px;
    margin-bottom: 45px;
    display: block;
}

.CardActivator_SuperclientLogo__1aKyP {
    margin-top: 5px;
    height: 50px;
    display: block;
}


.CardActivator_HeaderHead__3iIqD {
    margin-bottom: 0;
    font-weight: 500;
    display: block;
}

.CardActivator_HeaderText__35aTi {
    font-size: 0.85em;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    margin-bottom: 37px;
}

.CardActivator_CardActivator__1P0bK form {
    font-family: 'Source Sans Pro Semibold'
}

.CardActivator_CardActivator__1P0bK form input {
    font-size: 0.90em;
    font-weight: normal;
    width: 100%;
    background: #FFFFFF;
    border: 1.4px solid #BEBEBE;
    box-sizing: border-box;
    padding: 8px 15px;
    margin: 4px 0 0 0;
}

.CardActivator_CardActivator__1P0bK form label {
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: bold;
    letter-spacing: 0.01em;
    display: block;
    width: 100%;
}

.CardActivator_Activate__22BO3 {
    width: 56%;
    margin: 25px 22%;
    height: 45px;
    background: #FFC120;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.94em;
    letter-spacing: 0.02em;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    text-align: center;
    cursor: pointer;
}

.CardActivator_Activate__22BO3:hover {
    background: #FFD361;
}

.CardActivator_Activate__22BO3:disabled {
    background: #BEBEBE;
}

.CardActivator_Activate__22BO3:active {
    background: #E9A31D;
}

.CardActivator_Error__1Ou-z {
    color: red;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .CardActivator_CardActivator__1P0bK {
        width: 474px;
        margin-top: 70px;
        margin-left: 15.5%;
        height: 668px;
    }

    .CardActivator_HeaderHead__3iIqD {
        font-size: 28px;
        left: 20px;
    }

    .CardActivator_HeaderText__35aTi {
        font-size: 16px;
    }

    .CardActivator_Logo__3B9K0 {
        position: absolute;
        right: 10%;
        top: 8%;
    }

    .CardActivator_CardActivator__1P0bK form input {
        font-size: 18px;
        margin: 4px 0 0 0;
    }
    
    .CardActivator_CardActivator__1P0bK form label {
        font-size: 14px;
    }

    .CardActivator_Activate__22BO3 {
        font-size: 18px;
        height: 58px;
    }

    .CardActivator_HeaderWithImg__2OEtH {
        margin-left: 23px;
        margin-right: 4px;
        width: 361px;
        display: inline-block;
    }
    
    div.CardActivator_SuperclientLogo__1aKyP{
        margin-top: 0;
        height: 100%;
        width: 86px;    
        position: relative;
        /* top: -50px; */
        display: inline-block;
    }

    img.CardActivator_SuperclientLogo__1aKyP {
        width: 86px;
        height: 86px;
    }
  }

  
@media only screen and (min-width: 768px) and (max-width: 950px) {
    .CardActivator_Logo__3B9K0 {
        position: absolute;
        right: 5%;
        top: 2%;
    }
}

.InputElement_Invalid__2tsfj {
    border: 1.4px solid #FF424D !important;
}

.InputElement_Required__1F7Dq {
    color: #FF424D
}

.InputElement_CountryCode__2-p7G.InputElement_CountryCode__2-p7G {
    width: 33%;
    margin-right: 2%;
    display: inline-block;
    text-align: center;
}

.InputElement_Phone__3xGHZ.InputElement_Phone__3xGHZ {
    width: 63%;
    margin-left: 2%;
}

.InputElement_CheckboxLabel__1Fv35 {
    font-family: 'Source Sans Pro';
    text-transform: none;
    font-weight: normal;
    font-size: 1.3em;
    display: inline-block;
}

.InputElement_Checkbox__4pk4w {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    margin-top: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    width: 20px;
  }
   
  .InputElement_Checkbox__4pk4w input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .InputElement_Checkmark__1zopE {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: #FFFFFF;
    border: 1px solid #BEBEBE;
  }
  
  .InputElement_Checkbox__4pk4w input:checked ~ .InputElement_Checkmark__1zopE {
    background-color: #FFC120;
    border: 1px solid #FFC120;
  }
  
  .InputElement_Checkmark__1zopE:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .InputElement_Checkbox__4pk4w input:checked ~ .InputElement_Checkmark__1zopE:after {
    display: block;
  }
  
  .InputElement_Checkbox__4pk4w .InputElement_Checkmark__1zopE:after {
    left: 6px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .InputElement_Error__3YE_6 {
    color: red;
    font-weight: 400;
    font-family: 'Source Sans Pro';
    font-size: 0.75em;
    height: 18px;
    margin: 0;
    margin-left: 1px;
    margin-top: 5px;
  }

  @media only screen and (min-width: 768px) {
    .InputElement_Checkbox__4pk4w {
      margin-top: 0px;
    }

    .InputElement_CountryCode__2-p7G.InputElement_CountryCode__2-p7G {
      width: 31%;
      margin-right: 2%;
      display: inline-block;
      text-align: center;
  }
  
  .InputElement_Phone__3xGHZ.InputElement_Phone__3xGHZ {
      width: 65%;
      margin-left: 2%;
  }

  .InputElement_Checkmark__1zopE {
    margin-top:3px;
  }

  .InputElement_Error__3YE_6 {
    height: 30px;
  }
}
.LocationList_LocationList__2o-KU {
    font-family: 'Source Sans Pro';
    height: calc(100vh - 340px);
}

.LocationList_Location__2Hkd9 {
    margin-right: 18px;
    border-bottom: 1px solid #BEBEBE;
}

.LocationList_LocationName__1TsOA {
    font-family: 'Source Sans Pro Semibold';
    font-size: 1em;
    margin-top: 5px;
    margin-bottom: 3px;
}

.LocationList_LocationAddress___E_Nk {
    margin-top: 0px;
    margin-bottom: 6px;
    font-size: 0.93em;
    color: #585858;
}

@media only screen and (min-width: 768px) {
    .LocationList_LocationList__2o-KU {
        max-height: 535px;
        min-height: 100px;
        height: calc(100vh - 340px);
    }

    .LocationList_LocationName__1TsOA {
        font-size: 18px;
    }

    .LocationList_LocationAddress___E_Nk {
        font-size: 18px;
    }
  }
